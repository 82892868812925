<template>
  <b-container>
    <b-row>
      <div class="col-12">
        <b-row align-v="center" id="box" v-show="!displayWarningView">
          <b-col cols="12">
            <b-form @submit="onSubmit">
              <b-card class="text-start">
                <div class="container" data-cy="forgotPwd">
                  <div class="row">
                    <div class="col-12">
                      <b-form-group id="input-group-agency" label="CPF" label-for="document">
                        <b-form-input id="document" data-cy="document" type="text" min="0" v-model="form.document"
                          :state="testaCPF(form.document)" v-mask="'###########'" required></b-form-input>
                        <b-form-invalid-feedback :state="testaCPF(form.document)">
                          Informe um documento válido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>

                  <Recaptcha :sitekey="recaptchaSitekey" :callback="recaptchaCallBack" />
                  <br />

                  <div class="row">
                    <div class="col">
                      <b-button block variant="primary" class="sndbt" type="submit" :disabled="!isValid()">PROSSEGUIR
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
        <!--<b-row v-show="displayWarningView">
          <div>I am an element</div>
        </b-row>-->
      </div>
    </b-row>
  </b-container>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
export default {
  name: "Legacy",
  components: {
    Recaptcha,
  },
  async created() {
  },
  data() {
    return {
      recaptchaSitekey: "6LfRB3UfAAAAAA97QONUZX28OjdoXQoV1KGuuskd",
      form: {
        document: "",
        recaptcha: null,
      },
      id: "",
      displayWarningView: false,
      contact: null,
    };
  },
  methods: {
    recaptchaCallBack(token) {
      this.form.recaptcha = token;
    },
    isValid() {
      const isCpfValid = this.testaCPF(this.form.document);
      const rctok = this.form.recaptcha != null;
      return isCpfValid && rctok;
    },
    testaCPF(strCPF) {
      if (strCPF) {
        let sum = 0,
          leftovers,
          i;
        if (strCPF == "00000000000") return false;

        for (i = 1; i <= 9; i++)
          sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        leftovers = (sum * 10) % 11;

        if (leftovers == 10 || leftovers == 11) leftovers = 0;
        if (leftovers != parseInt(strCPF.substring(9, 10))) return false;

        sum = 0;
        for (i = 1; i <= 10; i++)
          sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        leftovers = (sum * 10) % 11;

        if (leftovers == 10 || leftovers == 11) leftovers = 0;
        if (leftovers != parseInt(strCPF.substring(10, 11))) return false;
        return true;
      }
    },
    onSubmit(event) {
      event.preventDefault();
      this.getEmailAndPhoeByCPF();
    },

    getEmailAndPhoeByCPF() {
      this.$root.loading = true;
      this.axios
        .post("password/legacy/account", {
          document: this.form.document,
          recaptcha: this.form.recaptcha
        })
        .then((resp) => {
          if (resp.data.status === true) {
            this.form.recaptcha = null;
            window.grecaptcha.reset();
            this.$root.loading = false;
            this.contact = JSON.parse(resp.data.message);
            this.confirmaDadosModal();
          } else {
            this.form.recaptcha = null;
            window.grecaptcha.reset();
            this.$root.loading = false;
            this.cadastroDesatualizadoModal();
          }
        }).catch(() => {
          this.$root.loading = false;
          this.erroNaRequisicao();
        });
    },

    cadastroDesatualizadoModal() {
      this.$swal.fire({
        title: '<strong>Cadastro Desatualizado</strong>',
        icon: 'error',
        text: 'Para alterar a sua senha é necessário realizar a atualização  do seu cadastro.',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "Atualizar Cadastro",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open("https://docktech.atlassian.net/");
        }
      });
    },

    confirmaDadosModal() {
      this.$swal.fire({
        title: '<strong>Confirme seus Dados</strong>',
        icon: 'question',
        html:
          'Para redefinir sua senha, você precisa ter acesso aos seguintes contatos cadastrados: </br> </br>' +
          `<label style="width: 100%;text-align: start;">
            <i class="fa-regular fa-envelope" style="color:#3085d6;"></i> <strong> E-mail: </strong> ${this.contact.email.toLowerCase()} 
          </label> </br>` +
          `<label style="width: 100%;text-align: start;">
            <i class="fas fa-phone" style="color:#3085d6;"></i> <strong> Telefone: </strong>  ${this.contact.telefone.toLowerCase()}
          </label>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "Tenho Acesso",
        cancelButtonText: "Não Tenho Acesso",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$root.loading = true;
          this.axios
            .post("password/legacy/send", {
              uuid_Id: this.contact.uuid
            })
            .then((resp) => {
              this.$root.loading = false;
              if (resp.data.status === true) {
                this.solicitacaoDeTrocaDeSenhaEnviada();
              } else if (resp.data.status === false  &&
                JSON.parse(resp.data.message).errors &&
                JSON.parse(resp.data.message).errors[0].code === 'Safety-008') {
                this.linkExpirado();
              } else {
                this.erroNaRequisicao();
              }
            }).catch(() => {
              this.$root.loading = false;
              this.erroNaRequisicao();
            });
        } else if (!result.isConfirmed && result.dismiss === 'cancel') {
          this.$root.loading = false;
          this.atualizeSeuCadastroModal();
        }
      });
    },

    atualizeSeuCadastroModal() {
      this.$swal.fire({
        icon: 'warning',
        text: 'Nesse Caso, você precisa realizar a atualização do seu cadadastro. Para isso, basta clicar no botão abaixo.',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: "Atualizar Cadastro",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open("https://docktech.atlassian.net/");
        }
      });
    },

    solicitacaoDeTrocaDeSenhaEnviada() {
      this.$swal.fire({
        title: '<i class="fa-regular fa-envelope" style="color:#3085d6;"></i> <strong> E-mail enviado! </stron>',
        icon: 'success',
        html:
          'Agora você deve seguir os seguintes passos: </br> </br>' +
          `<label style="width: 100%;text-align: start;">
              <label style="width: 25px;height: 25px;background: #ffc700;border-radius: 50%;text-align:center;font-weight: 600;padding-top: 3px;padding-left: 1px;margin-right: 10px;"> 1 </label>
              Acessar seu e-mail e clicar no link recebido.
            </label> </br>` +

          `<label style="width: 100%;text-align: start;">
              <label style="width: 25px;height: 25px;background: #ffc700;border-radius: 50%;text-align:center;font-weight: 600;padding-top: 3px;padding-left: 1px;margin-right: 10px;"> 2 </label>
              Digitar o código recebido via SMS.
           </label> </br>` +

          `<label style="width: 100%;text-align: start;">
                <label style="width: 25px;height: 25px;background: #ffc700;border-radius: 50%;text-align:center;font-weight: 600;padding-top: 3px;padding-left: 1px;margin-right: 10px;"> 3 </label>
                Inserir uma nova senha e confirmá-la.
             </label> </br>`,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        cancelButtonText: "Fechar",
      });
    },

    erroNaRequisicao() {
      this.$swal.fire({
        title: 'Ocorreu um problema na sua solicitação',
        icon: 'error',
        text: 'O serviço está temporariamente indisponível!',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        cancelButtonText: "Fechar",
      });
    },

    linkExpirado() {
      this.$swal.fire({
        title: 'Ocorreu um problema na sua solicitação',
        icon: 'error',
        text: 'Link de envio de sms e email para redefinição de senha expirou',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        cancelButtonText: "Fechar",
      });
    },
  },
  mounted() {
    this.$root.loading = false;
  },
};
</script>

<style scoped>
.home {
  font-size: 20px;
}

#box {
  margin-top: 18px;
}

.row {
  margin-bottom: 10px;
}

.action {
  width: 90%;
}

.btn-list {
  font-variant: all-small-caps;
}

.dropdown-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 10px;
}

.status-pwd {
  position: relative;
  top: 10px;
  font-size: 0.8em;
  color: rgb(65, 65, 65);
}

.bar-strength {
  width: 100%;
  height: 10px;
  background-color: rgb(219, 219, 219);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.sndbt {
  background-color: #0285c9;
  border-color: #0285c9;
  height: 60px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

[data-bar] {
  padding-right: 15px;
}

[data-bar] .col-3 {
  padding-right: 0px;
}

[data-bar="1"] [data-strength="1"],
[data-bar="2"] [data-strength="1"] {
  background-color: rgb(221, 88, 26);
}

[data-bar="2"] [data-strength="2"],
[data-bar="2"] [data-strength="1"] {
  background-color: rgb(204, 135, 0, 1);
}

[data-bar="3"] [data-strength="3"],
[data-bar="3"] [data-strength="1"],
[data-bar="3"] [data-strength="2"] {
  background-color: rgb(2, 133, 201, 1);
}

[data-bar="4"] [data-strength="4"],
[data-bar="4"] [data-strength="1"],
[data-bar="4"] [data-strength="2"],
[data-bar="4"] [data-strength="3"] {
  background-color: rgb(151, 233, 74);
}

@media (max-width: 576px) {
  .pwdRules {
    font-size: 0.88em;
  }
}
</style>
